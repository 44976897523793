const ja = {
    translation: {
        "title-toolTip": "さらに情報を表示",
        "button-device-error": "閉じる",
        "button-device-cancel": "閉じる",
        "device-message-error-first": "確認ダイアログが出たら、マイクとカメラの使用を ",
        "device-message-error-allow": "許可",
        "device-message-error-second": "してください。 ",
        "device-message-error-third": "問題が解決しない場合は",
        "device-message-error-link": "サポートサイト",
        "device-message-error-last": "を参照してください。",
        "device-message-error-img": "/img/ac-device-error-modal-ja.png",
        "request-has-been-sent": "入室リクエストを送信しました。リクエストが許可されるまでしばらくお待ちください。",
        "request-denied": "入室が許可されませんでした。<br>または部屋の所有者が不在のため入室リクエストで問題が発生した可能性もありますので、<br>しばらくしてから再度入室をお試しください。",
        "request-to-enter-the-room-has-arrived": "{0}さんより入室リクエストが届いています。",
        "allow-entry": "入室を許可する",
        "not-allowed": "許可しない",
        "requesting-if-no-one-is-in-the-room": "入室リクエストを送信しましたが、リクエストを許可できるユーザーが部屋の中にいませんでした。少し時間をおいてから再度入室をリクエストしてみてください。",
        "wording-when-permission-to-enter-the-room-is-not-obtained-for-about-30-seconds": "入室リクエストを送信しましたが、反応がありませんでした。しばらくしてから再度入室をお試しください。",
        logout: "ログアウト",
        login: "ログイン",
        login_auto_join: "ログインしてから通話を開始",
        "people-currently-in-the-room": "現在入室しているのは{0}名",
        "start-a-call": "通話を開始",
        "start-a-call-recvonly": "入室する",
        "click-here-for-the-support-site": "サポートサイトは<a target=\"_blank\" href=\"https://aircamp.us/support/system_manuals/6657/\" rel=\"noreferrer\">こちら</a>",
        "turn-off-the-mic": "マイクをOFF",
        "turn-on-the-mic": "マイクをON",
        "turn-off-the-camera": "カメラをOFF",
        "turn-on-the-camera": "カメラをON",
        "effect-settings": "エフェクト設定",
        "no-effect-blur": "効果なし＆ぼかし",
        backgrounds: "背景",
        "sound-setting-mode": "サウンド設定モード",
        "share-screen": "画面を共有",
        "stop-screen-sharing": "画面共有を停止",
        "you have shared your screen": "{0}の画面が全員に共有されています。",
        "you have stopped screen sharing": "{0} は画面共有を停止しました。",
        chat: "チャット",
        "close-chat": "チャットを閉じる",
        "participant-list": "参加者一覧",
        "close-participant-list": "参加者一覧を閉じる",
        option: "オプション",
        setting: "ビデオとオーディオ設定",
        camera: "カメラ",
        microphone: "マイク",
        speaker: "スピーカー",
        record: "録画",
        party: "パーティー",
        firework: "花火",
        "leave-call": "通話から退出",
        "Enlarged to fit everyone's screen": "全員の画面に拡大表示されます",
        "Reduce the screen that is stretched to everyone's screen": "全員の画面に拡大されている画面を縮小します",
        "Screen is shared with everyone": "{0}の画面が全員に共有されています",
        timer: "タイマー",
        start: "スタート",
        stop: "ストップ",
        "start a call": "通話を開始",
        "start a call the guest": "ゲストとして通話を開始する場合はゲスト名を入力してください。",
        "start a call ID AirCampus": "AirCampusのIDをお持ちの場合はログインしてから通話を開始してください。",
        "or start call": "または",
        name: "ゲスト名",
        cancel: "キャンセル",
        "message on call": "チャット",
        "select all": "全員",
        "send message": "メッセージを送信",
        "Download Current Participant List": "現在の参加者リストをダウンロード",
        "Sort by volume": "発言時間でソート",
        "Error call StopRecording": "StopRecording 呼び出しエラー",
        "Error call StartRecording": "StartRecording 呼び出しエラー",
        "You could not enter the room because the number of people who can enter was exceeded.": "入室可能な人数を超えたため、入室できませんでした。",
        "An error has occurred": "エラーが発生しました",
        "Recording was started": "{0}さんによって録画が開始されました。",
        "Recording was stopped": "{0}さんによって録画が停止されました。",
        "It was enlarged": "{0}さんによって拡大されました。",
        "It was reduced": "{0}さんによって縮小されました。",
        "The timer was canceled": "{0}さんによってタイマーがキャンセルされました。",
        "A timer has been set": "{0}さんによってタイマーがセットされました。",
        "Error call ListConnections": "ListConnections 呼び出しエラー",
        "Error call list rooms": "エラーコールリストルーム",
        "my room": "マイルーム",
        "For system administrators": "システム管理者用",
        "Current usage": "現在の利用状況",
        "Aoba-BBT, Inc.": "株式会社Aoba-BBT",
        "privacy policy": "プライバシーポリシー",
        "terms of service": "利用規約",
        "create room": "ルームを作成",
        "room name": "ルーム名",
        "Room ID": "ルームID",
        "Access right": "アクセス権",
        Create: "作成",
        Update: "更新",
        Anyone: "誰でも",
        "Only users with an AirCampus account": "AirCampusのアカウントを持っているユーザーのみ",
        "owner only": "オーナーのみ",
        delete: "削除",
        change: "変更",
        "update room": "{0}の詳細",
        Owner: "オーナー",
        "delete room": "部屋を削除",
        "Can I delete the room?": "部屋を削除しても良いでしょうか？",
        "You can not delete the room": "ルームがアクティブになっているため削除できません。",
        person: "（{0}人）",
        detail: "詳細",
        copied: "コピーされました",
        "URL for viewing": "視聴用URL",
        "entered the room": "{0}さんが入室しました。",
        "has left the room": "{0}さんが退室しました。",
        "Video file": "映像",
        "Created date": "作成日",
        "It will take some time until the recording file can be downloaded. Please wait.": "※録画ファイルがダウンロードできるようになるまで時間がかかります。しばらくお待ちください。",
        "meta-content": "AirCampus Sessionの{0}に入室するためのURLです。",
        "very stable network": "とても安定したネットワークです",
        "stable network": "安定したネットワークです",
        "unstable network": "不安定なネットワークです",
        "very unstable network": "とても不安定なネットワークです",
        "loading network": "ネットワークの読み込み中",
        "video quality": "ビデオの品質",
        "sub-title": "字幕",
        "Noise Suppression": "ノイズキャンセリング",
        "You can enter if the owner approves": "オーナーが承認した場合は入室可能",
        "You can enter the room if the AirCampus user who is in the room approves it": "入室しているAirCampusユーザーが承認した場合は入室可能",
        "If you know the URL, you can enter the room without approval": "URLを知っていれば承認なく入室可能",
        "AirCampus User": "AirCampus ユーザー",
        guest: "ゲスト",
        "Users who can enter the room": "入室可能なユーザー",
        "Guests are not allowed to enter without authorization": "ゲストは承認なく入室することはできません",
        managers: "オーナーと同じ権限のユーザー",
        "Up to 3 people with the same authority as the owner can register": "オーナーと同じ権限を持つ人が最大3人まで登録できます。",
        "Acting owner": "代理オーナー",
        "Please enter your ID": "IDを入力して下さい",
        Search: "検索",
        Add: "追加",
        "room details": "{0}の詳細",
        "sub room": "ブレークアウトルーム設定",
        owner: "オーナー",
        "Error call list access log": "エラーコールリストアクセスログ",
        "Session ID": "セッションID",
        "Account ID": "入室者ID",
        "Account Name": "入室者名",
        "Created Time": "入室日時",
        "Destroyed Time": "退室日時",
        User: "ユーザー",
        "Audio file": "録音ファイル",
        "This is a URL that allows you to participate only by viewing. People who enter the room from this URL can join the room as viewing-only guests.": "視聴のみの参加ができるURLです。このURLから入室した人は視聴専用ゲストとしてRoomに参加できます。",
        "Breakout Rooms": "{0} のメンバー割り当て",
        "Breakout Rooms notice": "{0} に割り当てたいメンバーにチェックを入れてください。",
        "no members to assign": "割り当てられるメンバーがいません。",
        "be-applicable": "適用",
        "user has joined room.": "{0}さんが{1}に入室しました。",
        "title breakout room": "ブレークアウトルーム",
        "title breakout main room": "メイン ルーム",
        "title breakout side room": "ブレークアウト ルーム",
        "assign-members": "メンバーを割り当て",
        "not-participant": "メンバーが割り当てられていません。",
        "add breakout room": "グループを追加",
        "breakout session time": "ブレークアウトセッションの時間",
        "start join breakout room": "ブレークアウトセッションを開始",
        "note breakout room": "オーナーと代理オーナーは自分でブレークアウトルームをを選んで入室してください。",
        "input adornment end": "(分)",
        "Editing is not allowed": "※Sessionの詳細画面から変更してください。",
        "Allow user pin": "拡大表示機能をユーザーとゲストも利用可能にする",
        "Beauty Effects": "美容効果",
        "Effect strength": "効果強度",
        "A network disconnection has been detected. Please wait while we try to reconnect.": "ネットワーク切断が検出されました。再接続を試みていますのでしばらくお待ちください。",
        "Mirroring webcam": "ミラーリング表示",
        "Turn off the mic": "マイクをオフにします",
        "Detailed settings for this Session": "このSessionの詳細設定",
        "Progress rate": "進捗",
        confirmation: "確認",
        "Do you want to delete the recording file?": "録画ファイルを削除しますか？",
        "Update record": "更新記録",
        share: "共有",
        "share record": "録画ファイルを共有する",
        "shared account": "共有アカウント",
        Download: "ダウンロード",
        "Select where to save the recording file": "録画ファイルの保存先を選択してください",
        "Start recording": "録画開始",
        "The recording data will be saved in the recording folder of the selected user.": "選択されたユーザーの録画フォルダに録画データが保存されます。",
        Summary: "要約",
        "Create summary": "概要の作成",
        "View Summary": "要約を表示",
        Close: "閉じる",
        "Edit video": "ビデオを編集する",
        "Start position": "開始位置",
        "Crop duration (s)": "クロップする尺 (s秒)",
        "Crop selected area": "選択した部分をクロップ",
        reset: "リセット",
        "Confirm Crop": "クロップを確定",
        Edited: "編集済み",
        "Your video is being cropped, please wait a moment until it is completed.": "動画のクロップ処理を行っています。完了まで少々お待ちください。",
        "User entry": "ユーザーの入室",
        "Guest entry": "ゲストの入室",
        "Entrance record": "入室記録",
        "Owner approval required": "オーナーの承認が必要",
        "AirCampus user approval required": "AirCampusユーザーの承認が必要",
        "If you know the URL you can enter": "URLを知っていれば入室可能",
        "Discontinue a breakout session": "ブレークアウトセッションを中断",
        "Create a summary of the recording and send it via email.": "録画データから要約を作成してメールで送信する。",
        "Recording file": "録画ファイル",
        "Recording date": "録画日",
        "Recording time": "録画時間",
        Transcription: "文字おこし",
        "AI Summary": "AI要約",
        Operation: "操作",
        Generate: "生成する",
        Sharing: "共有中",
        "Original file": "オリジナルファイル",
        "There is 1 minute left in your breakout rooms.": "ブレークアウトルームの残り時間はあと1分です。",
        "Brightness adjustment": "明るさ調整",
        "Display vertically": "縦表示にする",
        "Display horizontal": "横表示にする",
        "Session Admin Settings": "Sessionの管理者設定",
        "Do you want to delete it?": "削除しますか?",
        Name: "名前",
        "Registered Date": "登録日時",
        "Account already exists": "アカウントはすでに存在しています",
        "Logged in as": "{0}としてログインしています",
        "Super Admin": "スーパー管理者",
        "Session Admin": "セッション管理者",
        "Share computer audio": "コンピューターの音声を共有",
        "Stop transcription": "文字おこしを停止する",
        "Send a reaction": "リアクションを送信",
        "name sent a reaction": "{0}さんが{1}を送信しました。",
        "AirCampus Session can't use your camera": "AirCampus Session ではカメラを使用できません",
        "Close other apps that might be using your camera": "カメラを使用している可能性のある他のアプリを閉じる",
        "If using an external camera, disconnect and reconnect it": "外付けカメラを使用している場合は、プラグを抜いて再度差し込む",
        "Close and reopen your browser": "ブラウザを閉じ、再度開けてみる",
        "Try again": "もう一度やり直してください",
        "AirCampus Session can't use your mic": "AirCampus Session ではマイクを使用できません",
        "If using an external mic, disconnect and reconnect it": "外部マイクを使用している場合は、取り外し、再接続する",
        "Remove from the Session Room": "{0} をSession Roomから退室させる",
        "Are you sure you want to kick out of the Session Room?": "本当に{0} をSession Roomから退室させますか？",
        "Subtitle setting": "字幕設定",
        Language: "言語",
        "Subtitle translation": "字幕翻訳",
        Show: "字幕を表示",
        Hide: "字幕を非表示",
        "Subtitle distribution channel URL": "字幕配信チャンネルのURL",
        "Please select your translation language": "翻訳言語を選択してください",
        "under construction": "…",
        "Subtitle Translation": "字幕翻訳",
        "Other Settings": "他の設定",
        "Do not display the original text before translation": "翻訳前の原文を表示しない",
        "Stop auto-scrolling": "自動スクロールを停止する",
        "Open subtitle channel": "字幕配信チャンネルを開く",
        "The language you speak": "あなたが話す言語",
        "Display the speaker name": "発言者名を表示する",
        "The speaker language has been changed": "スピーカーの言語が変更されました。",
        "Translate the translated sentences": "翻訳された文を翻訳します",
        "Size setting of subtitles": "字幕のサイズ設定",
        Small: "小",
        Standard: "標準",
        Big: "大",
        "Choose the language you want to translate": "翻訳したい言語を選んでください",
        "If you do not wish to translate, simply press the close button.": "翻訳を希望しない場合はそのまま閉じるボタンを押してください。",
        "Network Status": "ネットワークステータス",
        "no network": "ネットワークなし",
        "Stop XX screen sharing": "{0}の画面共有を停止する",
        "XX screen sharing will now stop. Are you sure?": "{0}の画面共有を停止します。よろしいですか？",
        All: "全て",
        "Recording sharing settings": "録画の共有設定",
        Recording: "録画中",
        "Share Recordings": "録画の共有",
        "Choose who you want to share your recording with": "録画を共有するユーザーを選択してください",
        "Allow your browser to access your camera": "ブラウザにカメラへのアクセスを許可する",
        "Allow your browser to access your microphone": "ブラウザにマイクへのアクセスを許可する",
        "Please try one of the following methods:": "下記のいずれかの方法をお試しください。",
        "Translation settings": "翻訳設定",
        "Download subtitles": "字幕ダウンロード",
        "Please wait a moment before entering the room.": "入室できるまで少々お待ちください。",
        "You have left X.": "{0}から退室しました。"
    }
};

export default ja;
